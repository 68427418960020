'use strict';

angular.module('enervexSalesappApp').factory('FormatUtil', function LayoutUtil(_, Util, LengthUtil) {
	return {
		totalWithCommas:function(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		format:function(_value, format, obj, design) {
			var value = obj && obj.formatInches ? _value/12 : _value
			var result = null
			if (format == "splitComma") {
				if (value && value.indexOf(",") != -1) {
					result = value.replace(/,/g, ", ");
				} else {
					result = value
				}
			} else if (format === "$ 0,000.00"){
				if (value ==0 || value == null) {
					result = "$ 0.00"
				} else {
					var val = parseFloat(value);
					if (isNaN(val)) {
						return "$ 0.00";
					}
					var numberString = val.toFixed(2);
					result = "$ " + this.totalWithCommas(numberString);
				}
			} else if (format === "$ 0,000"){
				if (value ==0 || value == null) {
					result = "$ 0";
				} else {
					var val = parseFloat(value);
					if (isNaN(val)) {
						return "$ 0";
					}
					var numberString = val.toFixed(0);
					result = "$ " + this.totalWithCommas(numberString);
				}
			} else if (format === "0,000"){
				if (value ==0 || value == null) {
					result = "0";
				} else {
					var val = parseFloat(value);
					if (isNaN(val)) {
						return "0";
					}
					var numberString = val.toFixed(0);
					result = this.totalWithCommas(numberString);
				}
			} else if (format === "$ 0.00"){
				if (value ==0 || value == null) {
					result = "$ 0.00"
				} else {
					var val = parseFloat(value)
					if (isNaN(val)) {
						result = "$ 0.00";
					} else {
						var numberString = val.toFixed(2);
						result = "$ " + this.totalWithCommas(numberString);
					}
				}
			} else if (format === "0.00"){
				if (value ==0 || value == null) {
					result = "0.00"
				} else {
					var val2 = parseFloat(value);
					if (isNaN(val2)) {
						result = "0.00";
					} else {
						var numberString = val2.toFixed(2);
						result = numberString;
					}
				}
			} else if (format === "0.000"){
				if (value ==0 || value == null) {
					result = "0.000";
				} else {
					var val2 = parseFloat(value);
					if (isNaN(val2)) {
						result = "0.000";
					} else {
						var numberString = val2.toFixed(3);
						result = numberString;
					}
				}
			} else if (format === "0"){
				if (value ==0 || value == null) {
					result = "0";
				} else {
					var val3 = parseFloat(value);
					if (isNaN(val3)) {
						result = "0";
					} else {
						var numberString = val3.toFixed(0);
						result = numberString;
					}
				}
			} else if (format === "0.0"){
				if (value ==0 || value == null) {
					result = "0.0";
				} else {
					var val4 = parseFloat(value);
					if (isNaN(val4)) {
						result = "0.0";
					} else {
						var numberString = val4.toFixed(1);
						result = numberString;
					}
				}
			} else if (format === "0' 0.000"){
				if (value ==0 || value == null) {
					result = "0'";
				} else {
					var val5 = parseFloat(value)
					if (isNaN(val5)) {
						result = "0'"
					} else {
						result = this.formatLengthObj({
							num: val5, 
							ipAsInches:false,
							design: desgin,
							removeSpace: false,
							incPrecision: 3
						});
					}
				}
			} else if (format === "0' 0.00"){
				if (value ==0 || value == null) {
					result = "0'"
				} else {
					var val5 = parseFloat(value);
					if (isNaN(val5)) {
						result = "0'";
					} else {
						result = this.formatLengthObj({
							num: val5, 
							ipAsInches:false,
							design: desgin,
							removeSpace: false,
							incPrecision: 2
						});
					}
				}
			} else if (format === "0' 0.0"){
				if (value ==0 || value == null) {
					result = "0'";
				} else {
					var val5 = parseFloat(value)
					if (isNaN(val5)) {
						result = "0'";
					} else {
						result = this.formatLengthObj({
							num: val5, 
							ipAsInches:false,
							design: desgin,
							removeSpace: false,
							incPrecision: 1
						});
					}
				}
			} else if (format === "0' 0"){
				if (value ==0 || value == null) {
					result = "0'";
				} else {
					var val5 = parseFloat(value);
					if (isNaN(val5)) {
						result = "0'";
					} else {
						result = this.formatLengthObj({
							num: val5, 
							ipAsInches:false,
							design: desgin,
							removeSpace: false,
							incPrecision: 0
						});
					}
				}
			} else {
				result = value
			}
			if (obj && obj.isDiameter) {
				result = "\u00F8" + result;
			}
			return result
		},
		formatLengthObj: function (params) {
			var num = params.num, 
				ipAsInches = params.ipAsInches, 
				design = params.design, 
				removeSpace = params.removeSpace, 
				incPrecision = params.incPrecision;

			return this.formatLength(num, ipAsInches, design, removeSpace, incPrecision);
		},
		formatLength:function (num, ipAsInches, design, removeSpace, incPrecision) {
			var _incPrecision = (incPrecision == undefined) ? 3 : incPrecision;
			var units = (design && design.units) || "IP";
			if (units == "IP") {
				if (ipAsInches) {
					if (!num) {
						return "0";
					}
					return "" + num.toFixed(_incPrecision);
				} else {
					var ft = parseInt(num / 12);
					var inc = Math.round(parseFloat(num - (ft * 12)) * 1000) / 1000;
					if (ft > 0) {
						if (removeSpace) {
							return ft + "'" + inc.toFixed(_incPrecision);
						} else {
							return ft + "' " + inc.toFixed(_incPrecision);
						}

					} else {
						return inc.toFixed(_incPrecision);
					}
				}
			} else {
				return Math.round(parseFloat(num) * 1000) / 1000;
			}
		}
	}
})